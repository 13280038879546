
// @ts-ignore
import "./../../../node_modules/slick-carousel/slick/slick.min"

function fading(){
	//@ts-ignore
	let $ = jQuery || window.jQuery

	let increment = 0.045;
	let opacity = 0;
	let instance = window.setInterval(function() {
		$("#collaboration-bg-image").css("opacity", opacity)
		opacity = opacity + increment;
		if(opacity > 0.3){
			$("#ps-wheel-background").css("opacity", 0.3)
			window.clearInterval(instance);
		}
	},50)
}


function outfading(){
	//@ts-ignore
	let $ = jQuery || window.jQuery

	let increment = 0.045;
	let opacity = 0.3;
	let instance = window.setInterval(function() {
		$("#collaboration-bg-image").css("opacity", opacity)
		opacity = opacity - increment;
		if(opacity < 0){
			$("#ps-wheel-background").css("opacity", 0)
			window.clearInterval(instance);
		}
	},50)
}


window.addEventListener("load", () => {
	//@ts-ignore
	let $ = jQuery || window.jQuery

	let slickOpts = {
		dots: true,
		slidesToShow: 1,
		slidesToScroll: 1,
	};
	let carousel = $(".collaborations-carousel")

	carousel.on('init', function(event:any, slick:any){
		$("#collaboration-bg-image").attr("src", slick.$slides[slick.currentSlide].getAttribute("data-image"))
	});

	carousel.slick(slickOpts)

	carousel.on('afterChange', function(event:any, slick:any){
		$("#collaboration-bg-image").attr("src", slick.$slides[slick.currentSlide].getAttribute("data-image"))
		fading();
	});

	carousel.on('beforeChange', function(){
		outfading()
	});
})
